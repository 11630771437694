/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ProvinceResponse } from '../../models/province-response';

export interface GetProvinces$Params {
  countryId: number;
}

export function getProvinces(http: HttpClient, rootUrl: string, params: GetProvinces$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProvinceResponse>>> {
  const rb = new RequestBuilder(rootUrl, getProvinces.PATH, 'get');
  if (params) {
    rb.path('countryId', params.countryId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ProvinceResponse>>;
    })
  );
}

getProvinces.PATH = '/api/countries/{countryId}/provinces';
