/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getQuiz } from '../fn/dummies/get-quiz';
import { GetQuiz$Params } from '../fn/dummies/get-quiz';
import { GetQuizResponse } from '../models/get-quiz-response';
import { getReadDummiesByUser } from '../fn/dummies/get-read-dummies-by-user';
import { GetReadDummiesByUser$Params } from '../fn/dummies/get-read-dummies-by-user';
import { markDummyAsRead } from '../fn/dummies/mark-dummy-as-read';
import { MarkDummyAsRead$Params } from '../fn/dummies/mark-dummy-as-read';
import { ReadDummiesByUserResponse } from '../models/read-dummies-by-user-response';
import { validateQuizAnswers } from '../fn/dummies/validate-quiz-answers';
import { ValidateQuizAnswers$Params } from '../fn/dummies/validate-quiz-answers';
import { ValidateQuizAnswersResponse } from '../models/validate-quiz-answers-response';

@Injectable({ providedIn: 'root' })
export class DummiesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getQuiz()` */
  static readonly GetQuizPath = '/api/dummies/quiz';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQuiz()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuiz$Response(params?: GetQuiz$Params, context?: HttpContext): Observable<StrictHttpResponse<GetQuizResponse>> {
    return getQuiz(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getQuiz$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQuiz(params?: GetQuiz$Params, context?: HttpContext): Observable<GetQuizResponse> {
    return this.getQuiz$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetQuizResponse>): GetQuizResponse => r.body)
    );
  }

  /** Path part for operation `validateQuizAnswers()` */
  static readonly ValidateQuizAnswersPath = '/api/dummies/quiz';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateQuizAnswers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateQuizAnswers$Response(params: ValidateQuizAnswers$Params, context?: HttpContext): Observable<StrictHttpResponse<ValidateQuizAnswersResponse>> {
    return validateQuizAnswers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateQuizAnswers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateQuizAnswers(params: ValidateQuizAnswers$Params, context?: HttpContext): Observable<ValidateQuizAnswersResponse> {
    return this.validateQuizAnswers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ValidateQuizAnswersResponse>): ValidateQuizAnswersResponse => r.body)
    );
  }

  /** Path part for operation `getReadDummiesByUser()` */
  static readonly GetReadDummiesByUserPath = '/api/dummies/read-by-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReadDummiesByUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReadDummiesByUser$Response(params?: GetReadDummiesByUser$Params, context?: HttpContext): Observable<StrictHttpResponse<ReadDummiesByUserResponse>> {
    return getReadDummiesByUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReadDummiesByUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReadDummiesByUser(params?: GetReadDummiesByUser$Params, context?: HttpContext): Observable<ReadDummiesByUserResponse> {
    return this.getReadDummiesByUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReadDummiesByUserResponse>): ReadDummiesByUserResponse => r.body)
    );
  }

  /** Path part for operation `markDummyAsRead()` */
  static readonly MarkDummyAsReadPath = '/api/dummies/mark-as-read/{documentType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markDummyAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markDummyAsRead$Response(params: MarkDummyAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markDummyAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markDummyAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markDummyAsRead(params: MarkDummyAsRead$Params, context?: HttpContext): Observable<void> {
    return this.markDummyAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
