/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getCompanyPointsTotal } from '../fn/company-point/get-company-points-total';
import { GetCompanyPointsTotal$Params } from '../fn/company-point/get-company-points-total';

@Injectable({ providedIn: 'root' })
export class CompanyPointService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCompanyPointsTotal()` */
  static readonly GetCompanyPointsTotalPath = '/api/company-points-total';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyPointsTotal()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyPointsTotal$Response(params?: GetCompanyPointsTotal$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getCompanyPointsTotal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyPointsTotal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyPointsTotal(params?: GetCompanyPointsTotal$Params, context?: HttpContext): Observable<number> {
    return this.getCompanyPointsTotal$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
