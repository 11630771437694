import { APP_INITIALIZER, ApplicationConfig, DEFAULT_CURRENCY_CODE, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { AsyncPipe, I18nPluralPipe } from '@angular/common';
import { BlockUIModule } from 'primeng/blockui';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { provideRouter, TitleStrategy, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DoableApiModule } from '@app/core/api';
import { environment } from './environments/environment';
import { authenticationInitializer, AuthenticationService, CoreAuthenticationModule } from '@app/core/authentication';
import { AppInsightsModule, AppInsightsService } from '@omaxel/angular-ms-app-insights';
import { AppExceptionHandler } from '@app/ui/ui-errors';
import { ENVIRONMENT } from '@app/core/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DialogService } from 'primeng/dynamicdialog';
import { MsClarityModule } from '@app/core/ms-clarity';
import { TranslatedTitleStrategy } from './translated-title-strategy';
import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { acceptLanguageInterceptor, availableLanguages, htmlFontSize } from '@app/core/utils';

export function createTranslateLoader(httpHandler: HttpBackend): TranslateHttpLoader {
    return new TranslateHttpLoader(new HttpClient(httpHandler), './assets/i18n/', '.json');
}

export function initializeLogging(loggingService: AppInsightsService) {
    return (): Promise<void> => {
        return new Promise<void>((resolve) => {
            loggingService.init();
            resolve();
        });
    };
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(
            routes,
            withInMemoryScrolling({
                anchorScrolling: 'enabled',
                scrollPositionRestoration: 'enabled',
            }),
        ),
        provideHttpClient(withInterceptors([acceptLanguageInterceptor])),
        importProvidersFrom(
            AsyncPipe,
            BlockUIModule,
            ToastModule,
            ConfirmDialogModule,
            BrowserModule,
            BrowserAnimationsModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: createTranslateLoader,
                    deps: [HttpBackend],
                },
                defaultLanguage: availableLanguages.it,
            }),
            DoableApiModule.forRoot({ rootUrl: environment.apiUrl }),
            CoreAuthenticationModule.forRoot({
                auth: environment.auth,
                resourceServerUrl: environment.apiUrl,
                sessionExpiredRedirectUrl: '/',
                unauthorizedUrlBlacklist: [],
            }),
            AppInsightsModule.forRoot(environment.logging),
            MsClarityModule.forRoot({
                projectId: environment.clarityProjectId,
            }),
        ),
        { provide: LOCALE_ID, useValue: 'it-IT' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        {
            provide: ErrorHandler,
            useClass: AppExceptionHandler,
        },
        { provide: I18nPluralPipe, useClass: I18nPluralPipe },
        {
            provide: ENVIRONMENT,
            useValue: environment,
        },
        {
            provide: TitleStrategy,
            useClass: TranslatedTitleStrategy,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeLogging,
            deps: [AppInsightsService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: authenticationInitializer,
            multi: true,
            deps: [AuthenticationService],
        },
        MessageService,
        ConfirmationService,
        DialogService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings,
        },
        {
            provide: htmlFontSize,
            useValue: environment.htmlFontSize,
        },
    ],
};
