export function toCamelCase(value: string): string {
    const regExp = /[-_]\w/gi;
    return value.replace(regExp, (match) => {
        return match[1].toUpperCase();
    });
}

export function toSnakeCase(value: string): string {
    return value.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}
