/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CheckVatRequest } from '../../models/check-vat-request';
import { VatCheck } from '../../models/vat-check';

export interface CheckVat$Params {
      body: CheckVatRequest
}

export function checkVat(http: HttpClient, rootUrl: string, params: CheckVat$Params, context?: HttpContext): Observable<StrictHttpResponse<VatCheck>> {
  const rb = new RequestBuilder(rootUrl, checkVat.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<VatCheck>;
    })
  );
}

checkVat.PATH = '/api/companies/vat';
