import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CookieConsentService {
    private readonly storageKey: string = 'cookie-consent';
    private readonly consentValue: string = 'yes';

    public cookiesAccepted$ = new BehaviorSubject<boolean | null>(null);

    init(): void {
        this.cookiesAccepted$.next(this.hasConsent());
    }

    hasConsent(): boolean {
        const consent = localStorage.getItem(this.storageKey);
        return consent != null && consent === this.consentValue;
    }

    acceptCookies(): void {
        localStorage.setItem(this.storageKey, this.consentValue);
        this.cookiesAccepted$.next(true);
    }
}
