import { ErrorHandler, Inject, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Environment, ENVIRONMENT } from '@app/core/environment';
import { AppException } from '@app/core/exceptions';
import { ToastService } from '@app/ui/ui-toasts';

@Injectable()
export class AppExceptionHandler implements ErrorHandler {
    constructor(
        private toastService: ToastService,
        private zone: NgZone,
        @Inject(ENVIRONMENT) private env: Environment,
    ) {}

    handleError(error: unknown): void {
        if (!this.env.production) {
            console.error('AN ERROR OCCURRED: ', error);
        }
        this.zone.run(() => {
            if (error instanceof AppException) {
                this.toastService.appError(error);
            } else if (error instanceof HttpErrorResponse) {
                this.toastService.httpError(error);
            } else {
                console.error('AN ERROR OCCURRED: ', error);
                this.toastService.error(`L'operazione potrebbe non essere andata a buon fine`, 'Errore imprevisto');
            }
        });
    }
}
