import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ApiConfiguration } from '@app/core/api';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    private hubConnection?: signalR.HubConnection;

    receivedMessage$ = new Subject<{ messageId: number; chatId: number }>();
    hasUnreadMessages$ = new BehaviorSubject<boolean>(false);

    constructor(private config: ApiConfiguration) {}

    private startConnectionIfNotStarted(): void {
        if (!this.hubConnection) {
            this.hubConnection = new signalR.HubConnectionBuilder()
                .withUrl(`${this.config.rootUrl}/chatHub`, { withCredentials: true })
                .build();

            this.hubConnection.start();
        }
    }

    closeConnection(): void {
        this.hubConnection?.stop();
        this.hubConnection = undefined;
    }

    addMessageListener(): void {
        this.startConnectionIfNotStarted();

        this.hubConnection?.on('NewMessage', (messageId: string, chatId: string) => {
            this.receivedMessage$.next({
                messageId: +messageId,
                chatId: +chatId,
            });
            this.hasUnreadMessages$.next(true);
        });
    }
}
