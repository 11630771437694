import { AuthConfig } from 'angular-oauth2-oidc';

export const defaultAuthConfig: AuthConfig = {
    redirectUri: 'http://localhost:4200',
    responseType: 'code',
    requireHttps: false,
    scope: 'openid profile email offline_access',
    strictDiscoveryDocumentValidation: false,
};

export interface CoreAuthenticationConfig {
    auth?: AuthConfig;
    resourceServerUrl: string;
    sessionExpiredRedirectUrl?: string;
    unauthorizedUrlBlacklist?: string[];
}
