/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { acceptInvitation } from '../fn/supply-chain/accept-invitation';
import { AcceptInvitation$Params } from '../fn/supply-chain/accept-invitation';
import { AmbassadorWhitepaperDto } from '../models/ambassador-whitepaper-dto';
import { getAllAmbassadors } from '../fn/supply-chain/get-all-ambassadors';
import { GetAllAmbassadors$Params } from '../fn/supply-chain/get-all-ambassadors';
import { getAvailableWhitepaperYears } from '../fn/supply-chain/get-available-whitepaper-years';
import { GetAvailableWhitepaperYears$Params } from '../fn/supply-chain/get-available-whitepaper-years';
import { getInvitation } from '../fn/supply-chain/get-invitation';
import { GetInvitation$Params } from '../fn/supply-chain/get-invitation';
import { getSupplyChainMembersExcelTemplate } from '../fn/supply-chain/get-supply-chain-members-excel-template';
import { GetSupplyChainMembersExcelTemplate$Params } from '../fn/supply-chain/get-supply-chain-members-excel-template';
import { getWhitepaper } from '../fn/supply-chain/get-whitepaper';
import { GetWhitepaper$Params } from '../fn/supply-chain/get-whitepaper';
import { getWhitepaperLookups } from '../fn/supply-chain/get-whitepaper-lookups';
import { GetWhitepaperLookups$Params } from '../fn/supply-chain/get-whitepaper-lookups';
import { InvitationDto } from '../models/invitation-dto';
import { newSupplyChainMembers } from '../fn/supply-chain/new-supply-chain-members';
import { NewSupplyChainMembers$Params } from '../fn/supply-chain/new-supply-chain-members';
import { newSupplyChainMembersFromExcel$FormData } from '../fn/supply-chain/new-supply-chain-members-from-excel-form-data';
import { NewSupplyChainMembersFromExcel$FormData$Params } from '../fn/supply-chain/new-supply-chain-members-from-excel-form-data';
import { newSupplyChainMembersFromExcel$XWwwFormUrlencoded } from '../fn/supply-chain/new-supply-chain-members-from-excel-x-www-form-urlencoded';
import { NewSupplyChainMembersFromExcel$XWwwFormUrlencoded$Params } from '../fn/supply-chain/new-supply-chain-members-from-excel-x-www-form-urlencoded';
import { newSupplyChainMembersParseExcel$FormData } from '../fn/supply-chain/new-supply-chain-members-parse-excel-form-data';
import { NewSupplyChainMembersParseExcel$FormData$Params } from '../fn/supply-chain/new-supply-chain-members-parse-excel-form-data';
import { newSupplyChainMembersParseExcel$XWwwFormUrlencoded } from '../fn/supply-chain/new-supply-chain-members-parse-excel-x-www-form-urlencoded';
import { NewSupplyChainMembersParseExcel$XWwwFormUrlencoded$Params } from '../fn/supply-chain/new-supply-chain-members-parse-excel-x-www-form-urlencoded';
import { rejectInvitation } from '../fn/supply-chain/reject-invitation';
import { RejectInvitation$Params } from '../fn/supply-chain/reject-invitation';
import { updateWhitepaper } from '../fn/supply-chain/update-whitepaper';
import { UpdateWhitepaper$Params } from '../fn/supply-chain/update-whitepaper';
import { WhitepaperDto } from '../models/whitepaper-dto';
import { WhitepaperLookupsDto } from '../models/whitepaper-lookups-dto';
import { WhitepaperYearsDto } from '../models/whitepaper-years-dto';

@Injectable({ providedIn: 'root' })
export class SupplyChainService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `acceptInvitation()` */
  static readonly AcceptInvitationPath = '/api/supply-chain/invitation/{invitationCode}/accept';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptInvitation$Response(params: AcceptInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return acceptInvitation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptInvitation(params: AcceptInvitation$Params, context?: HttpContext): Observable<void> {
    return this.acceptInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAllAmbassadors()` */
  static readonly GetAllAmbassadorsPath = '/api/ambassadors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAmbassadors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAmbassadors$Response(params?: GetAllAmbassadors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AmbassadorWhitepaperDto>>> {
    return getAllAmbassadors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllAmbassadors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAmbassadors(params?: GetAllAmbassadors$Params, context?: HttpContext): Observable<Array<AmbassadorWhitepaperDto>> {
    return this.getAllAmbassadors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AmbassadorWhitepaperDto>>): Array<AmbassadorWhitepaperDto> => r.body)
    );
  }

  /** Path part for operation `getAvailableWhitepaperYears()` */
  static readonly GetAvailableWhitepaperYearsPath = '/api/whitepaper/years';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableWhitepaperYears()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableWhitepaperYears$Response(params?: GetAvailableWhitepaperYears$Params, context?: HttpContext): Observable<StrictHttpResponse<WhitepaperYearsDto>> {
    return getAvailableWhitepaperYears(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailableWhitepaperYears$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableWhitepaperYears(params?: GetAvailableWhitepaperYears$Params, context?: HttpContext): Observable<WhitepaperYearsDto> {
    return this.getAvailableWhitepaperYears$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhitepaperYearsDto>): WhitepaperYearsDto => r.body)
    );
  }

  /** Path part for operation `getInvitation()` */
  static readonly GetInvitationPath = '/api/supply-chain/invitation/{invitationCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvitation$Response(params: GetInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<InvitationDto>> {
    return getInvitation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvitation(params: GetInvitation$Params, context?: HttpContext): Observable<InvitationDto> {
    return this.getInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<InvitationDto>): InvitationDto => r.body)
    );
  }

  /** Path part for operation `getSupplyChainMembersExcelTemplate()` */
  static readonly GetSupplyChainMembersExcelTemplatePath = '/api/supply-chain/members/excel-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSupplyChainMembersExcelTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplyChainMembersExcelTemplate$Response(params?: GetSupplyChainMembersExcelTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getSupplyChainMembersExcelTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSupplyChainMembersExcelTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSupplyChainMembersExcelTemplate(params?: GetSupplyChainMembersExcelTemplate$Params, context?: HttpContext): Observable<string> {
    return this.getSupplyChainMembersExcelTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getWhitepaper()` */
  static readonly GetWhitepaperPath = '/api/whitepaper';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhitepaper()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhitepaper$Response(params?: GetWhitepaper$Params, context?: HttpContext): Observable<StrictHttpResponse<WhitepaperDto>> {
    return getWhitepaper(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWhitepaper$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhitepaper(params?: GetWhitepaper$Params, context?: HttpContext): Observable<WhitepaperDto> {
    return this.getWhitepaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhitepaperDto>): WhitepaperDto => r.body)
    );
  }

  /** Path part for operation `updateWhitepaper()` */
  static readonly UpdateWhitepaperPath = '/api/whitepaper';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateWhitepaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWhitepaper$Response(params: UpdateWhitepaper$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateWhitepaper(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateWhitepaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateWhitepaper(params: UpdateWhitepaper$Params, context?: HttpContext): Observable<void> {
    return this.updateWhitepaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getWhitepaperLookups()` */
  static readonly GetWhitepaperLookupsPath = '/api/whitepaper/lookups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhitepaperLookups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhitepaperLookups$Response(params?: GetWhitepaperLookups$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WhitepaperLookupsDto>>> {
    return getWhitepaperLookups(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWhitepaperLookups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhitepaperLookups(params?: GetWhitepaperLookups$Params, context?: HttpContext): Observable<Array<WhitepaperLookupsDto>> {
    return this.getWhitepaperLookups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WhitepaperLookupsDto>>): Array<WhitepaperLookupsDto> => r.body)
    );
  }

  /** Path part for operation `newSupplyChainMembers()` */
  static readonly NewSupplyChainMembersPath = '/api/supply-chain/members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newSupplyChainMembers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newSupplyChainMembers$Response(params: NewSupplyChainMembers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return newSupplyChainMembers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newSupplyChainMembers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newSupplyChainMembers(params: NewSupplyChainMembers$Params, context?: HttpContext): Observable<void> {
    return this.newSupplyChainMembers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `newSupplyChainMembersFromExcel()` */
  static readonly NewSupplyChainMembersFromExcelPath = '/api/supply-chain/members/from-excel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newSupplyChainMembersFromExcel$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  newSupplyChainMembersFromExcel$FormData$Response(params?: NewSupplyChainMembersFromExcel$FormData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return newSupplyChainMembersFromExcel$FormData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newSupplyChainMembersFromExcel$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  newSupplyChainMembersFromExcel$FormData(params?: NewSupplyChainMembersFromExcel$FormData$Params, context?: HttpContext): Observable<void> {
    return this.newSupplyChainMembersFromExcel$FormData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newSupplyChainMembersFromExcel$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  newSupplyChainMembersFromExcel$XWwwFormUrlencoded$Response(params?: NewSupplyChainMembersFromExcel$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return newSupplyChainMembersFromExcel$XWwwFormUrlencoded(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newSupplyChainMembersFromExcel$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  newSupplyChainMembersFromExcel$XWwwFormUrlencoded(params?: NewSupplyChainMembersFromExcel$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<void> {
    return this.newSupplyChainMembersFromExcel$XWwwFormUrlencoded$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `newSupplyChainMembersParseExcel()` */
  static readonly NewSupplyChainMembersParseExcelPath = '/api/supply-chain/members/parse-excel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newSupplyChainMembersParseExcel$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  newSupplyChainMembersParseExcel$FormData$Response(params?: NewSupplyChainMembersParseExcel$FormData$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return newSupplyChainMembersParseExcel$FormData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newSupplyChainMembersParseExcel$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  newSupplyChainMembersParseExcel$FormData(params?: NewSupplyChainMembersParseExcel$FormData$Params, context?: HttpContext): Observable<number> {
    return this.newSupplyChainMembersParseExcel$FormData$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newSupplyChainMembersParseExcel$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  newSupplyChainMembersParseExcel$XWwwFormUrlencoded$Response(params?: NewSupplyChainMembersParseExcel$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return newSupplyChainMembersParseExcel$XWwwFormUrlencoded(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newSupplyChainMembersParseExcel$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  newSupplyChainMembersParseExcel$XWwwFormUrlencoded(params?: NewSupplyChainMembersParseExcel$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<number> {
    return this.newSupplyChainMembersParseExcel$XWwwFormUrlencoded$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `rejectInvitation()` */
  static readonly RejectInvitationPath = '/api/supply-chain/invitation/{invitationCode}/reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectInvitation$Response(params: RejectInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return rejectInvitation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectInvitation(params: RejectInvitation$Params, context?: HttpContext): Observable<void> {
    return this.rejectInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
