/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EditorialCompanyCreateUpdateRequest } from '../../models/editorial-company-create-update-request';
import { EditorialCompanyResponse } from '../../models/editorial-company-response';

export interface UpdateEditorialCompany$Params {
  id: number;
      body: EditorialCompanyCreateUpdateRequest
}

export function updateEditorialCompany(http: HttpClient, rootUrl: string, params: UpdateEditorialCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<EditorialCompanyResponse>> {
  const rb = new RequestBuilder(rootUrl, updateEditorialCompany.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EditorialCompanyResponse>;
    })
  );
}

updateEditorialCompany.PATH = '/api/editorial/companies/{id}';
