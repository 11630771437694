/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ActivityFeedResponse } from '../../models/activity-feed-response';
import { TrendingType } from '../../models/trending-type';

export interface GetActivities$Params {
  page: number;
  pageSize: number;
  search?: string;
  categoryId?: number;
  companyCategoryId?: number;
  companySizeId?: number;
  countryId?: number;
  startPeriodDate?: string;
  endPeriodDate?: string;
  trendingType?: TrendingType;
  isFollowing?: boolean;
  companyTagId?: number;
  departmentId?: number;
  activityTypeValue?: number;
  showSupplyChain?: boolean;
}

export function getActivities(http: HttpClient, rootUrl: string, params: GetActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ActivityFeedResponse>>> {
  const rb = new RequestBuilder(rootUrl, getActivities.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('pageSize', params.pageSize, {});
    rb.query('search', params.search, {});
    rb.query('categoryId', params.categoryId, {});
    rb.query('companyCategoryId', params.companyCategoryId, {});
    rb.query('companySizeId', params.companySizeId, {});
    rb.query('countryId', params.countryId, {});
    rb.query('startPeriodDate', params.startPeriodDate, {});
    rb.query('endPeriodDate', params.endPeriodDate, {});
    rb.query('trendingType', params.trendingType, {});
    rb.query('isFollowing', params.isFollowing, {});
    rb.query('companyTagId', params.companyTagId, {});
    rb.query('departmentId', params.departmentId, {});
    rb.query('activityTypeValue', params.activityTypeValue, {});
    rb.query('showSupplyChain', params.showSupplyChain, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ActivityFeedResponse>>;
    })
  );
}

getActivities.PATH = '/api/activities';
