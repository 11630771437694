/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getDateTime } from '../fn/localization/get-date-time';
import { GetDateTime$Params } from '../fn/localization/get-date-time';
import { getText } from '../fn/localization/get-text';
import { GetText$Params } from '../fn/localization/get-text';

@Injectable({ providedIn: 'root' })
export class LocalizationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getDateTime()` */
  static readonly GetDateTimePath = '/api/localization/date-time';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDateTime()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDateTime$Response(params?: GetDateTime$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getDateTime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDateTime$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDateTime(params?: GetDateTime$Params, context?: HttpContext): Observable<string> {
    return this.getDateTime$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getText()` */
  static readonly GetTextPath = '/api/localization/text';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getText()` instead.
   *
   * This method doesn't expect any request body.
   */
  getText$Response(params?: GetText$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getText(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getText$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getText(params?: GetText$Params, context?: HttpContext): Observable<string> {
    return this.getText$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
