export enum RoutesEnum {
    ROOT = '',

    // Public pages
    CONTACTS = 'contact',
    FAQ = 'faq',
    FORGOT_PASSWORD = 'forgot-password',
    PRIVACY = 'privacy',
    REGISTER = 'register',
    REGISTER_USER = 'register-user',
    REGISTER_USER_OTP_CHECK = 'register-user-otp-check',
    RESET_PASSWORD = 'reset-password',
    REGULATION = 'regulation',
    COOKIE = 'cookie',
    TERMS_AND_CONDITIONS = 'terms-and-conditions',
    UNFAIR_TERMS = 'unfair-terms',

    // Feed
    FEED = 'activities',
    ENTITY_ID = ':id',
    COMPANIES = 'companies',
    CREATE_POST = 'create-post',
    EDIT_POST = 'edit-post',

    HELP_CENTER = 'help-center',
    TECHNICAL_SUPPORT = 'technical-support',

    // Dummies
    QUIZ = 'quiz',

    // Profile
    PROFILE = 'profile',
    PROFILE_FEED = 'feed',
    PROFILE_FEED_FILTER = ':filter',
    PROFILE_SUBSCRIPTION = 'subscription',
    PROFILE_CONNECTED_ACCOUNTS = 'connected-accounts',
    PROFILE_ACCOUNT_AND_PLAN = 'account-and-plan',
    PROFILE_SECURITY = 'security',
    PROFILE_INVITES = 'invites',
    PROFILE_VIEWER_LICENSES = 'viewer-licenses',
    PROFILE_AMPLIFY_CREDITS = 'amplify-credits',
    AMPLIFY_CREDITS_PURCHASE_SUCCESS = 'amplify-credits-purchase-success',
    AMPLIFY_CREDITS_PURCHASE_ERROR = 'amplify-credits-purchase-error',

    // Editorial
    EDITORIAL = 'editorial',
    EDITORIAL_DASHBOARD = 'dashboard',
    EDITORIAL_POSTS = 'posts',
    EDITORIAL_COMPANY_CATEGORIES = 'company-categories',
    EDITORIAL_POST_CATEGORIES = 'post-categories',
    EDITORIAL_USERS = 'users',
    EDITORIAL_COMPANIES = 'editorial-companies',
    EDITORIAL_COMPANIES_CREATE = 'new-editorial-companies',
    EDITORIAL_POST_CATEGORIES_CREATE = 'new-post-category',
    EDITORIAL_COMPANY_CATEGORIES_CREATE = 'new-company-category',
    EDITORIAL_COMPANY_DISCOUNTS = 'company-discount',
    EDITORIAL_COMPANY_DISCOUNT_ID = ':discountId',

    // Post Categories (sustainability categories)
    SUSTAINABILITY_CATEGORIES = 'sustainability-categories',

    // Company
    COMPANY = 'company',

    // Chat
    CHAT = 'chat',

    // Analytics
    ANALYTICS = 'analytics',

    // Supply Chain
    SUPPLY_CHAIN = 'supply-chain',
    SUPPLY_CHAIN_AMBASSADORS = 'ambassadors',
    SUPPLY_CHAIN_WHITEPAPER = 'supply-chain-whitepaper',
    SUPPLY_CHAIN_WHITEPAPER_EDIT = 'edit',
    SUPPLY_CHAIN_INVITE_CONFIRMATION = 'invite-confirmation',

    REGISTRATION_PURCHASE = 'registration-purchase',
    REGISTRATION_PURCHASE_SUCCESS = 'registration-purchase-success',
    REGISTRATION_PURCHASE_ERROR = 'registration-purchase-error',

    MEMBERSHIP_PURCHASE_SUCCESS = 'membership-purchase-success',
    MEMBERSHIP_PURCHASE_ERROR = 'membership-purchase-error',

    EDITORIAL_FINANCIAL_TRANSACTIONS = 'financial-transactions',

    SUBSCRIPTION_NOT_VALID = 'subscription-not-valid',

    // Follows
    COMPANY_TAGS = 'company-tags',
    COMPANY_TAGS_MANAGE = 'manage',

    //Dummies
    DUMMIES = 'dummies',
    DUMMY_TYPE = ':dummyType',
}
