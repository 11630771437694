/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createBankTransferMembershipsOrder } from '../fn/membership/create-bank-transfer-memberships-order';
import { CreateBankTransferMembershipsOrder$Params } from '../fn/membership/create-bank-transfer-memberships-order';
import { CreateBankTransferMembershipsOrderResponse } from '../models/create-bank-transfer-memberships-order-response';
import { createMembershipManageSession } from '../fn/membership/create-membership-manage-session';
import { CreateMembershipManageSession$Params } from '../fn/membership/create-membership-manage-session';
import { createMembershipRegistrationPurchaseSession } from '../fn/membership/create-membership-registration-purchase-session';
import { CreateMembershipRegistrationPurchaseSession$Params } from '../fn/membership/create-membership-registration-purchase-session';
import { createMembershipsPurchaseSession } from '../fn/membership/create-memberships-purchase-session';
import { CreateMembershipsPurchaseSession$Params } from '../fn/membership/create-memberships-purchase-session';
import { getMemberships } from '../fn/membership/get-memberships';
import { GetMemberships$Params } from '../fn/membership/get-memberships';
import { getMembershipsSummary } from '../fn/membership/get-memberships-summary';
import { GetMembershipsSummary$Params } from '../fn/membership/get-memberships-summary';
import { getSubscriptions } from '../fn/membership/get-subscriptions';
import { GetSubscriptions$Params } from '../fn/membership/get-subscriptions';
import { MembershipManageSessionCreatedResponse } from '../models/membership-manage-session-created-response';
import { MembershipPurchaseResponse } from '../models/membership-purchase-response';
import { MembershipPurchaseSessionCreatedResponse } from '../models/membership-purchase-session-created-response';
import { MembershipResponse } from '../models/membership-response';
import { MembershipSummaryResponse } from '../models/membership-summary-response';
import { saveStripeMembershipsPurchaseSessionDetailsSuccess } from '../fn/membership/save-stripe-memberships-purchase-session-details-success';
import { SaveStripeMembershipsPurchaseSessionDetailsSuccess$Params } from '../fn/membership/save-stripe-memberships-purchase-session-details-success';
import { saveStripePurchaseSessionDetailsFailed } from '../fn/membership/save-stripe-purchase-session-details-failed';
import { SaveStripePurchaseSessionDetailsFailed$Params } from '../fn/membership/save-stripe-purchase-session-details-failed';
import { saveStripeRegistrationPurchaseSessionDetailsSuccess } from '../fn/membership/save-stripe-registration-purchase-session-details-success';
import { SaveStripeRegistrationPurchaseSessionDetailsSuccess$Params } from '../fn/membership/save-stripe-registration-purchase-session-details-success';
import { SubscriptionResponsePaging } from '../models/subscription-response-paging';

@Injectable({ providedIn: 'root' })
export class MembershipService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createMembershipManageSession()` */
  static readonly CreateMembershipManageSessionPath = '/api/memberships/manage-session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMembershipManageSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  createMembershipManageSession$Response(params?: CreateMembershipManageSession$Params, context?: HttpContext): Observable<StrictHttpResponse<MembershipManageSessionCreatedResponse>> {
    return createMembershipManageSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMembershipManageSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createMembershipManageSession(params?: CreateMembershipManageSession$Params, context?: HttpContext): Observable<MembershipManageSessionCreatedResponse> {
    return this.createMembershipManageSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<MembershipManageSessionCreatedResponse>): MembershipManageSessionCreatedResponse => r.body)
    );
  }

  /** Path part for operation `createMembershipRegistrationPurchaseSession()` */
  static readonly CreateMembershipRegistrationPurchaseSessionPath = '/api/memberships/{membershipId}/create-registration-session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMembershipRegistrationPurchaseSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  createMembershipRegistrationPurchaseSession$Response(params: CreateMembershipRegistrationPurchaseSession$Params, context?: HttpContext): Observable<StrictHttpResponse<MembershipPurchaseSessionCreatedResponse>> {
    return createMembershipRegistrationPurchaseSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMembershipRegistrationPurchaseSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createMembershipRegistrationPurchaseSession(params: CreateMembershipRegistrationPurchaseSession$Params, context?: HttpContext): Observable<MembershipPurchaseSessionCreatedResponse> {
    return this.createMembershipRegistrationPurchaseSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<MembershipPurchaseSessionCreatedResponse>): MembershipPurchaseSessionCreatedResponse => r.body)
    );
  }

  /** Path part for operation `createMembershipsPurchaseSession()` */
  static readonly CreateMembershipsPurchaseSessionPath = '/api/memberships/create-purchase-session';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createMembershipsPurchaseSession()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMembershipsPurchaseSession$Response(params: CreateMembershipsPurchaseSession$Params, context?: HttpContext): Observable<StrictHttpResponse<MembershipPurchaseSessionCreatedResponse>> {
    return createMembershipsPurchaseSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createMembershipsPurchaseSession$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createMembershipsPurchaseSession(params: CreateMembershipsPurchaseSession$Params, context?: HttpContext): Observable<MembershipPurchaseSessionCreatedResponse> {
    return this.createMembershipsPurchaseSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<MembershipPurchaseSessionCreatedResponse>): MembershipPurchaseSessionCreatedResponse => r.body)
    );
  }

  /** Path part for operation `getMemberships()` */
  static readonly GetMembershipsPath = '/api/memberships';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMemberships()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMemberships$Response(params?: GetMemberships$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MembershipResponse>>> {
    return getMemberships(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMemberships$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMemberships(params?: GetMemberships$Params, context?: HttpContext): Observable<Array<MembershipResponse>> {
    return this.getMemberships$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MembershipResponse>>): Array<MembershipResponse> => r.body)
    );
  }

  /** Path part for operation `getSubscriptions()` */
  static readonly GetSubscriptionsPath = '/api/memberships/subscriptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubscriptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptions$Response(params: GetSubscriptions$Params, context?: HttpContext): Observable<StrictHttpResponse<SubscriptionResponsePaging>> {
    return getSubscriptions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubscriptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptions(params: GetSubscriptions$Params, context?: HttpContext): Observable<SubscriptionResponsePaging> {
    return this.getSubscriptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubscriptionResponsePaging>): SubscriptionResponsePaging => r.body)
    );
  }

  /** Path part for operation `saveStripeMembershipsPurchaseSessionDetailsSuccess()` */
  static readonly SaveStripeMembershipsPurchaseSessionDetailsSuccessPath = '/api/memberships/save-stripe-memberships-purchase-session-details-success';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveStripeMembershipsPurchaseSessionDetailsSuccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeMembershipsPurchaseSessionDetailsSuccess$Response(params: SaveStripeMembershipsPurchaseSessionDetailsSuccess$Params, context?: HttpContext): Observable<StrictHttpResponse<MembershipPurchaseResponse>> {
    return saveStripeMembershipsPurchaseSessionDetailsSuccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveStripeMembershipsPurchaseSessionDetailsSuccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeMembershipsPurchaseSessionDetailsSuccess(params: SaveStripeMembershipsPurchaseSessionDetailsSuccess$Params, context?: HttpContext): Observable<MembershipPurchaseResponse> {
    return this.saveStripeMembershipsPurchaseSessionDetailsSuccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<MembershipPurchaseResponse>): MembershipPurchaseResponse => r.body)
    );
  }

  /** Path part for operation `saveStripePurchaseSessionDetailsFailed()` */
  static readonly SaveStripePurchaseSessionDetailsFailedPath = '/api/memberships/save-stripe-purchase-session-details-failed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveStripePurchaseSessionDetailsFailed()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripePurchaseSessionDetailsFailed$Response(params: SaveStripePurchaseSessionDetailsFailed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveStripePurchaseSessionDetailsFailed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveStripePurchaseSessionDetailsFailed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripePurchaseSessionDetailsFailed(params: SaveStripePurchaseSessionDetailsFailed$Params, context?: HttpContext): Observable<void> {
    return this.saveStripePurchaseSessionDetailsFailed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `saveStripeRegistrationPurchaseSessionDetailsSuccess()` */
  static readonly SaveStripeRegistrationPurchaseSessionDetailsSuccessPath = '/api/memberships/save-stripe-registration-purchase-session-details-success';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveStripeRegistrationPurchaseSessionDetailsSuccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeRegistrationPurchaseSessionDetailsSuccess$Response(params: SaveStripeRegistrationPurchaseSessionDetailsSuccess$Params, context?: HttpContext): Observable<StrictHttpResponse<MembershipPurchaseResponse>> {
    return saveStripeRegistrationPurchaseSessionDetailsSuccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveStripeRegistrationPurchaseSessionDetailsSuccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveStripeRegistrationPurchaseSessionDetailsSuccess(params: SaveStripeRegistrationPurchaseSessionDetailsSuccess$Params, context?: HttpContext): Observable<MembershipPurchaseResponse> {
    return this.saveStripeRegistrationPurchaseSessionDetailsSuccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<MembershipPurchaseResponse>): MembershipPurchaseResponse => r.body)
    );
  }

  /** Path part for operation `createBankTransferMembershipsOrder()` */
  static readonly CreateBankTransferMembershipsOrderPath = '/api/company/memberships/create-bank-transfer-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBankTransferMembershipsOrder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBankTransferMembershipsOrder$Response(params: CreateBankTransferMembershipsOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateBankTransferMembershipsOrderResponse>> {
    return createBankTransferMembershipsOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBankTransferMembershipsOrder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBankTransferMembershipsOrder(params: CreateBankTransferMembershipsOrder$Params, context?: HttpContext): Observable<CreateBankTransferMembershipsOrderResponse> {
    return this.createBankTransferMembershipsOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateBankTransferMembershipsOrderResponse>): CreateBankTransferMembershipsOrderResponse => r.body)
    );
  }

  /** Path part for operation `getMembershipsSummary()` */
  static readonly GetMembershipsSummaryPath = '/api/company/memberships/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMembershipsSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMembershipsSummary$Response(params?: GetMembershipsSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<MembershipSummaryResponse>> {
    return getMembershipsSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMembershipsSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMembershipsSummary(params?: GetMembershipsSummary$Params, context?: HttpContext): Observable<MembershipSummaryResponse> {
    return this.getMembershipsSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<MembershipSummaryResponse>): MembershipSummaryResponse => r.body)
    );
  }

}
