import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { AuthService } from './services/auth.service';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { CoreAuthenticationConfig, defaultAuthConfig } from './configs/auth.config';
import { SESSION_EXPIRED_REDIRECT_URL, UNAUTHORIZED_URL_BLACKLIST } from './configs/injection-tokens.config';
import { UserDirective } from './directives/user.directive';
import { IsAuthDirective } from './directives/is-auth.directive';

export function initializeAuth(authService: AuthService): () => Promise<void> {
    return () => authService.runInitialLoginSequence();
}
export function storageFactory(): OAuthStorage {
    return localStorage;
}

@NgModule({
    imports: [OAuthModule.forRoot()],
    declarations: [IsAuthDirective, UserDirective],
    exports: [IsAuthDirective, UserDirective],
})
export class CoreAuthenticationModule {
    static forRoot(params: CoreAuthenticationConfig): ModuleWithProviders<CoreAuthenticationModule> {
        return {
            ngModule: CoreAuthenticationModule,
            providers: [
                { provide: APP_INITIALIZER, useFactory: initializeAuth, deps: [AuthService], multi: true },
                {
                    provide: AuthConfig,
                    useValue: {
                        ...defaultAuthConfig,
                        ...(params.auth ?? {}),
                    },
                },
                {
                    provide: OAuthModuleConfig,
                    useValue: {
                        resourceServer: {
                            allowedUrls: [params.resourceServerUrl],
                            sendAccessToken: true,
                        },
                    },
                },
                { provide: OAuthStorage, useFactory: storageFactory },
                { provide: SESSION_EXPIRED_REDIRECT_URL, useValue: params.sessionExpiredRedirectUrl },
                { provide: UNAUTHORIZED_URL_BLACKLIST, useValue: params.unauthorizedUrlBlacklist },
            ],
        };
    }
}
