import { Injectable } from '@angular/core';
import { interval, Observable, of, pipe, ReplaySubject, switchMap } from 'rxjs';
import { debounce, finalize, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    public _loading$ = new ReplaySubject<boolean>();
    private counter = 0;

    public loading$: Observable<boolean> = this._loading$.asObservable().pipe(debounce(() => interval(250)));

    public show(): void {
        if (this.counter === 0) {
            this._loading$.next(true);
        }
        this.counter += 1;
    }

    public hide(force = false): void {
        if (force) {
            this.counter = 0;
        } else {
            this.counter = Math.max(this.counter - 1, 0);
        }

        if (this.counter === 0) {
            this._loading$.next(false);
        }
    }

    public withLoading<T>(): (source: Observable<T>) => Observable<T> {
        return pipe(
            this.startWithTap(() => this.show()),
            finalize(() => this.hide()),
        );
    }

    private startWithTap<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
        return (source: Observable<T>): Observable<T> =>
            of({}).pipe(
                tap(callback),
                switchMap(() => source),
            );
    }
}
