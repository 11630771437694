import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

export const acceptLanguageInterceptor: HttpInterceptorFn = (req, next) => {
    try {
        const translate = inject(TranslateService);
        const selectedLanguage = translate.currentLang || translate.defaultLang;

        const modifiedReq = req.clone({
            setHeaders: {
                'Accept-Language': selectedLanguage,
            },
        });

        return next(modifiedReq);
    } catch {
        return next(req);
    }
};
