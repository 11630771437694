import { DestroyRef, Directive, ElementRef, inject, Inject, InjectionToken, Input, OnInit, Renderer2 } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { round } from 'lodash';
import { MediaQueryService } from '../services/media-query.service';
import { tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const htmlFontSize = new InjectionToken<number>('htmlFontSize');

function getComputedSize(value: number | undefined, htmlFontSize: number): number | undefined {
    return value == null ? value : round(value * htmlFontSize, 2);
}

function updateImageSize(
    renderer: Renderer2,
    elementRef: ElementRef,
    fontSize: number,
    width: number | undefined,
    height: number | undefined,
): void {
    renderer.setStyle(elementRef.nativeElement, 'height', `${getComputedSize(height, fontSize)}`);
    renderer.setStyle(elementRef.nativeElement, 'width', `${getComputedSize(width, fontSize)}`);
}

const htmlFontSize100Percent = 1;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'img[doableImg]',
    standalone: true,
})
export class ImageFontSizeDirective extends NgOptimizedImage implements OnInit {
    private readonly destroyRef = inject(DestroyRef);

    override ngOnInit(): void {
        /* NOTE: changing the value of input properties in the ngOnInit method is not recommended,
         * still it is the only way to change the value of the input properties before the ngOnInit
         * of the parent class. */
        this.height = getComputedSize(this.height, this.htmlFontSize);
        this.width = getComputedSize(this.width, this.htmlFontSize);
        super.ngOnInit();

        // Listen for media query changes and adjust sizes accordingly
        this.mediaQueryService.mobileMediaQueryChanged$
            .pipe(
                tap((isMobile) => {
                    const htmlFontSize = isMobile === true ? htmlFontSize100Percent : this.htmlFontSize;
                    updateImageSize(this.rendererCustom, this.elementRef, htmlFontSize, this.width, this.height);
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    constructor(
        @Inject(htmlFontSize) private htmlFontSize: number,
        private mediaQueryService: MediaQueryService,
        private elementRef: ElementRef,
        private rendererCustom: Renderer2,
    ) {
        super();
    }
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'img[doableRawImg]',
    standalone: true,
})
export class RawImageFontSizeDirective implements OnInit {
    @Input() height?: number;
    @Input() width?: number;

    private readonly destroyRef = inject(DestroyRef);

    ngOnInit(): void {
        const computedHeight = getComputedSize(this.height, this.htmlFontSize);
        const computedWidth = getComputedSize(this.width, this.htmlFontSize);

        this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${computedHeight}`);
        this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${computedWidth}`);

        // Listen for media query changes and adjust sizes accordingly
        this.mediaQueryService.mobileMediaQueryChanged$
            .pipe(
                tap((isMobile) => {
                    const htmlFontSize = isMobile === true ? htmlFontSize100Percent : this.htmlFontSize;
                    updateImageSize(this.renderer, this.elementRef, htmlFontSize, this.width, this.height);
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe();
    }

    constructor(
        @Inject(htmlFontSize) private htmlFontSize: number,
        private mediaQueryService: MediaQueryService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {}
}
