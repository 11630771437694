import { bootstrapApplication } from '@angular/platform-browser';
import { AppRootComponent } from './app/root/app-root.component';
import { appConfig } from './app.config';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { environment } from './environments/environment';

declare global {
    interface Window {
        appVersion: string;
    }
}

window.appVersion = environment.version;

bootstrapApplication(AppRootComponent, appConfig).catch((err) => console.error(err));
registerLocaleData(localeIt);
