/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { UserAdminResponse } from '../../models/user-admin-response';

export interface GetUserAdmin$Params {
  id: number;
}

export function getUserAdmin(http: HttpClient, rootUrl: string, params: GetUserAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<UserAdminResponse>> {
  const rb = new RequestBuilder(rootUrl, getUserAdmin.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UserAdminResponse>;
    })
  );
}

getUserAdmin.PATH = '/api/editorial/user-admins/{id}';
