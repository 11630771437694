import { Routes } from '@angular/router';
import { notAuthenticatedUserRouteGuard } from '@app/core/authentication';

const _routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@app/pages/pages-main').then((m) => m.PagesMainRoutes),
    },
    {
        path: '',
        canActivate: [notAuthenticatedUserRouteGuard],
        loadChildren: () => import('@app/pages/pages-feed').then((m) => m.PagesFeedRoutes),
    },
    {
        path: '',
        canActivate: [notAuthenticatedUserRouteGuard],
        loadChildren: () => import('@app/pages/pages-editorial').then((m) => m.PagesEditorialRoutes),
    },
    {
        path: '',
        canActivate: [notAuthenticatedUserRouteGuard],
        loadChildren: () => import('@app/pages/pages-chat').then((m) => m.PagesChatRoutes),
    },
    {
        path: '**',
        redirectTo: '',
    },
];

export const routes = _routes;
