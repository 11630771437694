import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslatedTitleStrategy extends TitleStrategy {
    private title = inject(Title);
    private translateService = inject(TranslateService);

    private subscription: Subscription | null = null;

    updateTitle(snapshot: RouterStateSnapshot): void {
        this.subscription?.unsubscribe();

        const titleTranslationKey = this.buildTitle(snapshot) || '';

        if (!titleTranslationKey) {
            return;
        }

        this.subscription = this.translateService.get(titleTranslationKey).subscribe((translatedTitle) => {
            this.title.setTitle(`${translatedTitle} - aDoormore`);
        });
    }
}
