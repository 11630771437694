/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AvailablePostLanguageResponse } from '../../models/available-post-language-response';

export interface GetSupportedPostLanguages$Params {
}

export function getSupportedPostLanguages(http: HttpClient, rootUrl: string, params?: GetSupportedPostLanguages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AvailablePostLanguageResponse>>> {
  const rb = new RequestBuilder(rootUrl, getSupportedPostLanguages.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<AvailablePostLanguageResponse>>;
    })
  );
}

getSupportedPostLanguages.PATH = '/api/post/supported-post-languages';
