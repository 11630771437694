/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AmplifyCreditsRateSummaryResponse } from '../../models/amplify-credits-rate-summary-response';

export interface GetAmplifyCreditsRates$Params {
}

export function getAmplifyCreditsRates(http: HttpClient, rootUrl: string, params?: GetAmplifyCreditsRates$Params, context?: HttpContext): Observable<StrictHttpResponse<AmplifyCreditsRateSummaryResponse>> {
  const rb = new RequestBuilder(rootUrl, getAmplifyCreditsRates.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AmplifyCreditsRateSummaryResponse>;
    })
  );
}

getAmplifyCreditsRates.PATH = '/api/amplify-credits/rates';
