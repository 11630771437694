/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AmplifyCreditsPurchaseSessionCreatedResponse } from '../../models/amplify-credits-purchase-session-created-response';
import { AmplifyCreditsRateOrderRequest } from '../../models/amplify-credits-rate-order-request';

export interface CreateAmplifyCreditsPurchaseSession$Params {
      body: AmplifyCreditsRateOrderRequest
}

export function createAmplifyCreditsPurchaseSession(http: HttpClient, rootUrl: string, params: CreateAmplifyCreditsPurchaseSession$Params, context?: HttpContext): Observable<StrictHttpResponse<AmplifyCreditsPurchaseSessionCreatedResponse>> {
  const rb = new RequestBuilder(rootUrl, createAmplifyCreditsPurchaseSession.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AmplifyCreditsPurchaseSessionCreatedResponse>;
    })
  );
}

createAmplifyCreditsPurchaseSession.PATH = '/api/amplify-credits/create-purchase-session';
