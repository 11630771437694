import { Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { filter, switchMap } from 'rxjs/operators';
import { RoutesEnum } from '@app/core/utils';
import { map, Observable } from 'rxjs';

export function notAuthenticatedUserRouteGuard(): Observable<true | UrlTree> {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);

    return authenticationService.identityFetching$.pipe(
        filter((fetching) => !fetching),
        switchMap(() => authenticationService.identity$),
        map((identity) => {
            if (!identity) {
                return router.createUrlTree([RoutesEnum.ROOT]);
            }

            return true;
        }),
    );
}
