/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getNotifications } from '../fn/notification/get-notifications';
import { GetNotifications$Params } from '../fn/notification/get-notifications';
import { GetNotificationsResponsePaging } from '../models/get-notifications-response-paging';
import { getUnreadNotificationsCount } from '../fn/notification/get-unread-notifications-count';
import { GetUnreadNotificationsCount$Params } from '../fn/notification/get-unread-notifications-count';
import { markAllNotificationsAsRead } from '../fn/notification/mark-all-notifications-as-read';
import { MarkAllNotificationsAsRead$Params } from '../fn/notification/mark-all-notifications-as-read';
import { markNotificationAsRead } from '../fn/notification/mark-notification-as-read';
import { MarkNotificationAsRead$Params } from '../fn/notification/mark-notification-as-read';

@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getNotifications()` */
  static readonly GetNotificationsPath = '/api/notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications$Response(params: GetNotifications$Params, context?: HttpContext): Observable<StrictHttpResponse<GetNotificationsResponsePaging>> {
    return getNotifications(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNotifications(params: GetNotifications$Params, context?: HttpContext): Observable<GetNotificationsResponsePaging> {
    return this.getNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetNotificationsResponsePaging>): GetNotificationsResponsePaging => r.body)
    );
  }

  /** Path part for operation `getUnreadNotificationsCount()` */
  static readonly GetUnreadNotificationsCountPath = '/api/notifications-count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnreadNotificationsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnreadNotificationsCount$Response(params?: GetUnreadNotificationsCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return getUnreadNotificationsCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnreadNotificationsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnreadNotificationsCount(params?: GetUnreadNotificationsCount$Params, context?: HttpContext): Observable<number> {
    return this.getUnreadNotificationsCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `markAllNotificationsAsRead()` */
  static readonly MarkAllNotificationsAsReadPath = '/api/notifications/mark-all-as-read';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAllNotificationsAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAllNotificationsAsRead$Response(params?: MarkAllNotificationsAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markAllNotificationsAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markAllNotificationsAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markAllNotificationsAsRead(params?: MarkAllNotificationsAsRead$Params, context?: HttpContext): Observable<void> {
    return this.markAllNotificationsAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `markNotificationAsRead()` */
  static readonly MarkNotificationAsReadPath = '/api/notifications/{id}/mark-as-read';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markNotificationAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  markNotificationAsRead$Response(params: MarkNotificationAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return markNotificationAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `markNotificationAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  markNotificationAsRead(params: MarkNotificationAsRead$Params, context?: HttpContext): Observable<void> {
    return this.markNotificationAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
