/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountConnectionDataResponse } from '../models/account-connection-data-response';
import { AccountConnectionLinkedinOrganizationResponse } from '../models/account-connection-linkedin-organization-response';
import { changePassword } from '../fn/user/change-password';
import { ChangePassword$Params } from '../fn/user/change-password';
import { getConnectedAccountsData } from '../fn/user/get-connected-accounts-data';
import { GetConnectedAccountsData$Params } from '../fn/user/get-connected-accounts-data';
import { getLinkedinOrganizations } from '../fn/user/get-linkedin-organizations';
import { GetLinkedinOrganizations$Params } from '../fn/user/get-linkedin-organizations';
import { getProfileData } from '../fn/user/get-profile-data';
import { GetProfileData$Params } from '../fn/user/get-profile-data';
import { getUrlConnectAccountLinkedin } from '../fn/user/get-url-connect-account-linkedin';
import { GetUrlConnectAccountLinkedin$Params } from '../fn/user/get-url-connect-account-linkedin';
import { getUserInvitation } from '../fn/user/get-user-invitation';
import { GetUserInvitation$Params } from '../fn/user/get-user-invitation';
import { registerUser } from '../fn/user/register-user';
import { RegisterUser$Params } from '../fn/user/register-user';
import { registerUserOtp } from '../fn/user/register-user-otp';
import { RegisterUserOtp$Params } from '../fn/user/register-user-otp';
import { registerUserSendOtp } from '../fn/user/register-user-send-otp';
import { RegisterUserSendOtp$Params } from '../fn/user/register-user-send-otp';
import { saveLinkedinOauthToken } from '../fn/user/save-linkedin-oauth-token';
import { SaveLinkedinOauthToken$Params } from '../fn/user/save-linkedin-oauth-token';
import { saveLinkedinOrganization } from '../fn/user/save-linkedin-organization';
import { SaveLinkedinOrganization$Params } from '../fn/user/save-linkedin-organization';
import { unlinkAccountLinkedin } from '../fn/user/unlink-account-linkedin';
import { UnlinkAccountLinkedin$Params } from '../fn/user/unlink-account-linkedin';
import { updateCoverPicture } from '../fn/user/update-cover-picture';
import { UpdateCoverPicture$Params } from '../fn/user/update-cover-picture';
import { UpdatePictureResponse } from '../models/update-picture-response';
import { updateProfileData } from '../fn/user/update-profile-data';
import { UpdateProfileData$Params } from '../fn/user/update-profile-data';
import { updateProfilePicture } from '../fn/user/update-profile-picture';
import { UpdateProfilePicture$Params } from '../fn/user/update-profile-picture';
import { UserInvitationDto } from '../models/user-invitation-dto';
import { UserProfileDataResponse } from '../models/user-profile-data-response';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `changePassword()` */
  static readonly ChangePasswordPath = '/api/user/change-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword$Response(params: ChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePassword(params: ChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.changePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getProfileData()` */
  static readonly GetProfileDataPath = '/api/user/profile-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileData$Response(params?: GetProfileData$Params, context?: HttpContext): Observable<StrictHttpResponse<UserProfileDataResponse>> {
    return getProfileData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProfileData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileData(params?: GetProfileData$Params, context?: HttpContext): Observable<UserProfileDataResponse> {
    return this.getProfileData$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfileDataResponse>): UserProfileDataResponse => r.body)
    );
  }

  /** Path part for operation `updateProfileData()` */
  static readonly UpdateProfileDataPath = '/api/user/profile-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfileData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileData$Response(params: UpdateProfileData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateProfileData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProfileData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileData(params: UpdateProfileData$Params, context?: HttpContext): Observable<void> {
    return this.updateProfileData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUserInvitation()` */
  static readonly GetUserInvitationPath = '/api/user/invitation/{invitationCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInvitation$Response(params: GetUserInvitation$Params, context?: HttpContext): Observable<StrictHttpResponse<UserInvitationDto>> {
    return getUserInvitation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInvitation(params: GetUserInvitation$Params, context?: HttpContext): Observable<UserInvitationDto> {
    return this.getUserInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInvitationDto>): UserInvitationDto => r.body)
    );
  }

  /** Path part for operation `registerUser()` */
  static readonly RegisterUserPath = '/api/user/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser$Response(params: RegisterUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser(params: RegisterUser$Params, context?: HttpContext): Observable<void> {
    return this.registerUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registerUserOtp()` */
  static readonly RegisterUserOtpPath = '/api/user/register-otp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUserOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUserOtp$Response(params: RegisterUserOtp$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerUserOtp(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerUserOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUserOtp(params: RegisterUserOtp$Params, context?: HttpContext): Observable<void> {
    return this.registerUserOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registerUserSendOtp()` */
  static readonly RegisterUserSendOtpPath = '/api/user/register-send-otp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUserSendOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUserSendOtp$Response(params: RegisterUserSendOtp$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerUserSendOtp(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerUserSendOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUserSendOtp(params: RegisterUserSendOtp$Params, context?: HttpContext): Observable<void> {
    return this.registerUserSendOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateCoverPicture()` */
  static readonly UpdateCoverPicturePath = '/api/user/cover';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCoverPicture()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCoverPicture$Response(params?: UpdateCoverPicture$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdatePictureResponse>> {
    return updateCoverPicture(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCoverPicture$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCoverPicture(params?: UpdateCoverPicture$Params, context?: HttpContext): Observable<UpdatePictureResponse> {
    return this.updateCoverPicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdatePictureResponse>): UpdatePictureResponse => r.body)
    );
  }

  /** Path part for operation `updateProfilePicture()` */
  static readonly UpdateProfilePicturePath = '/api/user/profile-picture';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfilePicture()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateProfilePicture$Response(params?: UpdateProfilePicture$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdatePictureResponse>> {
    return updateProfilePicture(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProfilePicture$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateProfilePicture(params?: UpdateProfilePicture$Params, context?: HttpContext): Observable<UpdatePictureResponse> {
    return this.updateProfilePicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdatePictureResponse>): UpdatePictureResponse => r.body)
    );
  }

  /** Path part for operation `getConnectedAccountsData()` */
  static readonly GetConnectedAccountsDataPath = '/api/user/connected-accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConnectedAccountsData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectedAccountsData$Response(params?: GetConnectedAccountsData$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountConnectionDataResponse>> {
    return getConnectedAccountsData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConnectedAccountsData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConnectedAccountsData(params?: GetConnectedAccountsData$Params, context?: HttpContext): Observable<AccountConnectionDataResponse> {
    return this.getConnectedAccountsData$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountConnectionDataResponse>): AccountConnectionDataResponse => r.body)
    );
  }

  /** Path part for operation `getLinkedinOrganizations()` */
  static readonly GetLinkedinOrganizationsPath = '/api/user/connected-accounts/linkedin/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLinkedinOrganizations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLinkedinOrganizations$Response(params?: GetLinkedinOrganizations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AccountConnectionLinkedinOrganizationResponse>>> {
    return getLinkedinOrganizations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLinkedinOrganizations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLinkedinOrganizations(params?: GetLinkedinOrganizations$Params, context?: HttpContext): Observable<Array<AccountConnectionLinkedinOrganizationResponse>> {
    return this.getLinkedinOrganizations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AccountConnectionLinkedinOrganizationResponse>>): Array<AccountConnectionLinkedinOrganizationResponse> => r.body)
    );
  }

  /** Path part for operation `saveLinkedinOrganization()` */
  static readonly SaveLinkedinOrganizationPath = '/api/user/connected-accounts/linkedin/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveLinkedinOrganization()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveLinkedinOrganization$Response(params: SaveLinkedinOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveLinkedinOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveLinkedinOrganization$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveLinkedinOrganization(params: SaveLinkedinOrganization$Params, context?: HttpContext): Observable<void> {
    return this.saveLinkedinOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getUrlConnectAccountLinkedin()` */
  static readonly GetUrlConnectAccountLinkedinPath = '/api/user/connected-accounts/linkedin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUrlConnectAccountLinkedin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlConnectAccountLinkedin$Response(params?: GetUrlConnectAccountLinkedin$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getUrlConnectAccountLinkedin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUrlConnectAccountLinkedin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUrlConnectAccountLinkedin(params?: GetUrlConnectAccountLinkedin$Params, context?: HttpContext): Observable<string> {
    return this.getUrlConnectAccountLinkedin$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `unlinkAccountLinkedin()` */
  static readonly UnlinkAccountLinkedinPath = '/api/user/connected-accounts/linkedin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unlinkAccountLinkedin()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlinkAccountLinkedin$Response(params?: UnlinkAccountLinkedin$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return unlinkAccountLinkedin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unlinkAccountLinkedin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unlinkAccountLinkedin(params?: UnlinkAccountLinkedin$Params, context?: HttpContext): Observable<void> {
    return this.unlinkAccountLinkedin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `saveLinkedinOauthToken()` */
  static readonly SaveLinkedinOauthTokenPath = '/api/user/connected-accounts/linkedin/oauth-token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveLinkedinOauthToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveLinkedinOauthToken$Response(params: SaveLinkedinOauthToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return saveLinkedinOauthToken(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveLinkedinOauthToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveLinkedinOauthToken(params: SaveLinkedinOauthToken$Params, context?: HttpContext): Observable<void> {
    return this.saveLinkedinOauthToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
