/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AvailablePackageToAssignToMemberResponse } from '../models/available-package-to-assign-to-member-response';
import { CompanyMemberResponsePaging } from '../models/company-member-response-paging';
import { CompanyMembersOverviewTotalsResponse } from '../models/company-members-overview-totals-response';
import { deleteCompanyLink } from '../fn/company-member/delete-company-link';
import { DeleteCompanyLink$Params } from '../fn/company-member/delete-company-link';
import { deleteCompanyMember } from '../fn/company-member/delete-company-member';
import { DeleteCompanyMember$Params } from '../fn/company-member/delete-company-member';
import { getAvailablePackagesToAssignToMember } from '../fn/company-member/get-available-packages-to-assign-to-member';
import { GetAvailablePackagesToAssignToMember$Params } from '../fn/company-member/get-available-packages-to-assign-to-member';
import { getCompanyMembers } from '../fn/company-member/get-company-members';
import { GetCompanyMembers$Params } from '../fn/company-member/get-company-members';
import { getCompanyMembersExcelTemplate } from '../fn/company-member/get-company-members-excel-template';
import { GetCompanyMembersExcelTemplate$Params } from '../fn/company-member/get-company-members-excel-template';
import { getCompanyMembersOverviewTotals } from '../fn/company-member/get-company-members-overview-totals';
import { GetCompanyMembersOverviewTotals$Params } from '../fn/company-member/get-company-members-overview-totals';
import { getCompanyMembersXlsx } from '../fn/company-member/get-company-members-xlsx';
import { GetCompanyMembersXlsx$Params } from '../fn/company-member/get-company-members-xlsx';
import { newCompanyMembers } from '../fn/company-member/new-company-members';
import { NewCompanyMembers$Params } from '../fn/company-member/new-company-members';
import { newCompanyMembersFromExcel$FormData } from '../fn/company-member/new-company-members-from-excel-form-data';
import { NewCompanyMembersFromExcel$FormData$Params } from '../fn/company-member/new-company-members-from-excel-form-data';
import { newCompanyMembersFromExcel$XWwwFormUrlencoded } from '../fn/company-member/new-company-members-from-excel-x-www-form-urlencoded';
import { NewCompanyMembersFromExcel$XWwwFormUrlencoded$Params } from '../fn/company-member/new-company-members-from-excel-x-www-form-urlencoded';
import { newCompanyMembersParseExcel$FormData } from '../fn/company-member/new-company-members-parse-excel-form-data';
import { NewCompanyMembersParseExcel$FormData$Params } from '../fn/company-member/new-company-members-parse-excel-form-data';
import { newCompanyMembersParseExcel$XWwwFormUrlencoded } from '../fn/company-member/new-company-members-parse-excel-x-www-form-urlencoded';
import { NewCompanyMembersParseExcel$XWwwFormUrlencoded$Params } from '../fn/company-member/new-company-members-parse-excel-x-www-form-urlencoded';
import { updateCompanyMember } from '../fn/company-member/update-company-member';
import { UpdateCompanyMember$Params } from '../fn/company-member/update-company-member';

@Injectable({ providedIn: 'root' })
export class CompanyMemberService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deleteCompanyLink()` */
  static readonly DeleteCompanyLinkPath = '/api/company/link/{companyLinkId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyLink$Response(params: DeleteCompanyLink$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCompanyLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyLink(params: DeleteCompanyLink$Params, context?: HttpContext): Observable<void> {
    return this.deleteCompanyLink$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateCompanyMember()` */
  static readonly UpdateCompanyMemberPath = '/api/company/members/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyMember()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyMember$Response(params: UpdateCompanyMember$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateCompanyMember(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyMember$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyMember(params: UpdateCompanyMember$Params, context?: HttpContext): Observable<void> {
    return this.updateCompanyMember$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deleteCompanyMember()` */
  static readonly DeleteCompanyMemberPath = '/api/company/members/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyMember()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyMember$Response(params: DeleteCompanyMember$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteCompanyMember(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyMember$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyMember(params: DeleteCompanyMember$Params, context?: HttpContext): Observable<void> {
    return this.deleteCompanyMember$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getAvailablePackagesToAssignToMember()` */
  static readonly GetAvailablePackagesToAssignToMemberPath = '/api/company/members/available-packages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailablePackagesToAssignToMember()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailablePackagesToAssignToMember$Response(params?: GetAvailablePackagesToAssignToMember$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AvailablePackageToAssignToMemberResponse>>> {
    return getAvailablePackagesToAssignToMember(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAvailablePackagesToAssignToMember$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailablePackagesToAssignToMember(params?: GetAvailablePackagesToAssignToMember$Params, context?: HttpContext): Observable<Array<AvailablePackageToAssignToMemberResponse>> {
    return this.getAvailablePackagesToAssignToMember$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AvailablePackageToAssignToMemberResponse>>): Array<AvailablePackageToAssignToMemberResponse> => r.body)
    );
  }

  /** Path part for operation `getCompanyMembers()` */
  static readonly GetCompanyMembersPath = '/api/company/members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyMembers$Response(params: GetCompanyMembers$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyMemberResponsePaging>> {
    return getCompanyMembers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyMembers(params: GetCompanyMembers$Params, context?: HttpContext): Observable<CompanyMemberResponsePaging> {
    return this.getCompanyMembers$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyMemberResponsePaging>): CompanyMemberResponsePaging => r.body)
    );
  }

  /** Path part for operation `newCompanyMembers()` */
  static readonly NewCompanyMembersPath = '/api/company/members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyMembers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newCompanyMembers$Response(params: NewCompanyMembers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return newCompanyMembers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newCompanyMembers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newCompanyMembers(params: NewCompanyMembers$Params, context?: HttpContext): Observable<void> {
    return this.newCompanyMembers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCompanyMembersExcelTemplate()` */
  static readonly GetCompanyMembersExcelTemplatePath = '/api/company/members/excel-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyMembersExcelTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyMembersExcelTemplate$Response(params?: GetCompanyMembersExcelTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getCompanyMembersExcelTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyMembersExcelTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyMembersExcelTemplate(params?: GetCompanyMembersExcelTemplate$Params, context?: HttpContext): Observable<string> {
    return this.getCompanyMembersExcelTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getCompanyMembersOverviewTotals()` */
  static readonly GetCompanyMembersOverviewTotalsPath = '/api/company/members/overview-totals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyMembersOverviewTotals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyMembersOverviewTotals$Response(params?: GetCompanyMembersOverviewTotals$Params, context?: HttpContext): Observable<StrictHttpResponse<CompanyMembersOverviewTotalsResponse>> {
    return getCompanyMembersOverviewTotals(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyMembersOverviewTotals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyMembersOverviewTotals(params?: GetCompanyMembersOverviewTotals$Params, context?: HttpContext): Observable<CompanyMembersOverviewTotalsResponse> {
    return this.getCompanyMembersOverviewTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<CompanyMembersOverviewTotalsResponse>): CompanyMembersOverviewTotalsResponse => r.body)
    );
  }

  /** Path part for operation `getCompanyMembersXlsx()` */
  static readonly GetCompanyMembersXlsxPath = '/api/company/members/xlsx';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyMembersXlsx()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyMembersXlsx$Response(params?: GetCompanyMembersXlsx$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getCompanyMembersXlsx(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyMembersXlsx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyMembersXlsx(params?: GetCompanyMembersXlsx$Params, context?: HttpContext): Observable<Blob> {
    return this.getCompanyMembersXlsx$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `newCompanyMembersFromExcel()` */
  static readonly NewCompanyMembersFromExcelPath = '/api/company/members/from-excel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyMembersFromExcel$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  newCompanyMembersFromExcel$FormData$Response(params?: NewCompanyMembersFromExcel$FormData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return newCompanyMembersFromExcel$FormData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newCompanyMembersFromExcel$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  newCompanyMembersFromExcel$FormData(params?: NewCompanyMembersFromExcel$FormData$Params, context?: HttpContext): Observable<void> {
    return this.newCompanyMembersFromExcel$FormData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyMembersFromExcel$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  newCompanyMembersFromExcel$XWwwFormUrlencoded$Response(params?: NewCompanyMembersFromExcel$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return newCompanyMembersFromExcel$XWwwFormUrlencoded(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newCompanyMembersFromExcel$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  newCompanyMembersFromExcel$XWwwFormUrlencoded(params?: NewCompanyMembersFromExcel$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<void> {
    return this.newCompanyMembersFromExcel$XWwwFormUrlencoded$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `newCompanyMembersParseExcel()` */
  static readonly NewCompanyMembersParseExcelPath = '/api/company/members/parse-excel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyMembersParseExcel$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  newCompanyMembersParseExcel$FormData$Response(params?: NewCompanyMembersParseExcel$FormData$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return newCompanyMembersParseExcel$FormData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newCompanyMembersParseExcel$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  newCompanyMembersParseExcel$FormData(params?: NewCompanyMembersParseExcel$FormData$Params, context?: HttpContext): Observable<number> {
    return this.newCompanyMembersParseExcel$FormData$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyMembersParseExcel$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  newCompanyMembersParseExcel$XWwwFormUrlencoded$Response(params?: NewCompanyMembersParseExcel$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return newCompanyMembersParseExcel$XWwwFormUrlencoded(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newCompanyMembersParseExcel$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  newCompanyMembersParseExcel$XWwwFormUrlencoded(params?: NewCompanyMembersParseExcel$XWwwFormUrlencoded$Params, context?: HttpContext): Observable<number> {
    return this.newCompanyMembersParseExcel$XWwwFormUrlencoded$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
